import * as basicLightbox from 'basiclightbox'

let images = document.querySelectorAll(".fancybox");

for (let i = 0; i < images.length; i++) {
    let element = images[i];
    let imageLink = element.getAttribute("href");
    element.setAttribute("href", "#/");
    element.onclick = () => {
        const instance = basicLightbox.create(`
            <img src="${imageLink}">
            `);
        instance.show();
    }
}
